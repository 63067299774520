import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';
import { getStorage } from '../../components/utilityfunctions';

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async () => {
    let endPoint = "settingswithoutauth";
    if(getStorage('isLoggedIn')){
      endPoint = "settings";
    }
    const response = await http.get(endPoint);
    return response.data;
  }
);

export const fetchNotification = createAsyncThunk(
  'settings/fetchNotification',
  async () => {
     const response = await http.get('admin/notification');
    return response.data;
  }
);

export const fetchPromotions = createAsyncThunk(
  'settings/fetchPromotions',
  async () => {
     const response = await http.get('/admin/listpromotions');
    return response.data;
  }
);





const initialState = {
  sitesettings: [],
  notification:[],
  status: 'idle',
  notificationstatus: 'idle',
  promotions:[],
  promotionStatus:'idle',
  error: null,
};


const settingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    resetNotification(state){
      state.notificationstatus = "idle";
      state.notification = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sitesettings = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchNotification.pending, (state) => {
        state.notificationstatus = 'loading';
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.notificationstatus = 'succeeded';
        state.notification = action.payload;
      })
      .addCase(fetchNotification.rejected, (state, action) => {
        state.notificationstatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPromotions.pending, (state) => {
        state.promotionStatus = 'loading';
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.promotionStatus = 'succeeded';
        state.promotions = action.payload;
      })
      .addCase(fetchPromotions.rejected, (state, action) => {
        state.promotionStatus = 'failed';
        state.error = action.error.message;
      })
      
  },
});

export const { resetNotification } = settingsSlice.actions;
export default settingsSlice.reducer;