import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';
import FreeOfferProducts from '../checkout/FreeOfferproduct';


export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (payload) => {
    const response = await http.post('products', payload);
    return response.data.data;
  }
);

export const getOfferProducts = createAsyncThunk(
  'products/getOfferProducts',
  async (payload) => {
    const response = await http.post('productsbyids', payload);
    return response.data.data;
  }
);

export const getFreeProducts = createAsyncThunk(
  'products/getFreeProducts',
  async (payload) => {
    const response = await http.post('freeproductsbyids', payload);
    return response.data.data;
  }
);

export const getFreeOfferProducts = createAsyncThunk(
  'products/getFreeOfferProducts',
  async (payload) => {
    const response = await http.post('freeproductsbyids', payload);
    return response.data.data;
  }
);


const initialState = {
  productList: [],
  offerProductList: [],
  freeProductList: [],
  FreeOfferProducts: [],
  status: 'idle',
  triggerFreeProductListPop: false,
  error: null,
};


const productsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    triggerFreeProductList(state, action) {
      state.triggerFreeProductListPop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOfferProducts.fulfilled, (state, action) => {

        state.offerProductList = action.payload;
      })
      .addCase(getOfferProducts.rejected, (state) => {
        state.status = 'failed';
        state.offerProductList = [];
      })
      .addCase(getFreeProducts.fulfilled, (state, action) => {

        state.freeProductList = action.payload;
      })
      .addCase(getFreeProducts.rejected, (state) => {
        state.status = 'failed';
        state.freeProductList = [];
      })
      .addCase(getFreeOfferProducts.fulfilled, (state, action) => {
        state.FreeOfferProducts = action.payload;
      })
      .addCase(getFreeOfferProducts.rejected, (state) => {
        state.status = 'failed';
        state.FreeOfferProducts = [];
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.productList = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { triggerFreeProductList } = productsSlice.actions;
export default productsSlice.reducer;