import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Popup from "../../components/common/popup";
import { addPromoToCart } from "../cart/cartSlice";
import { priceFormat, getStorage, getOfferPrice } from "../../components/utilityfunctions";

const FreeOfferProducts = ({ closefreepro }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [offerPercentage] = useState(0);
  const [freeProductNew, setFreeProductNew] = useState([]);
  const [productValue, setProductValue] = useState([]);
  const [offerproductQty, setOfferproductQty] = useState(0);

  const { cartItems } = useSelector((state) => state.cart);
  const { FreeOfferProducts: freeProductData } = useSelector((state) => state.products);

  useEffect(() => {
    const productList = freeProductData.map((product) => ({
      ...product,
      productId: product.productNumber,
      qty: 0,
    }));

    const updatedProductList = productList.map((item) => {
      const cartItem = cartItems.find(
        (freeItem) =>
          (freeItem.productType === "Promo") &&
          freeItem.productNumber === item.productId
      );
      return cartItem ? { ...item, qty: cartItem.qty } : item;
    });

    setFreeProductNew(updatedProductList);
    setProductValue(updatedProductList);

  }, [cartItems, freeProductData]);

  const handleFreeProductUpdate = (event, isIncrease, item, index) => {
    event.preventDefault();
    const incrementLimit = 1;

    const updatedQty = isIncrease ? item.qty + 1 : Math.max(item.qty - 1, 0);

    if (
      (isIncrease && offerproductQty < incrementLimit) ||
      (!isIncrease && item.qty > 0)
    ) {
      const updatedProductValue = [...productValue];
      updatedProductValue[index].qty = updatedQty;

      const updatedFreeProductNew = [...freeProductNew];
      updatedFreeProductNew[index].qty = updatedQty;

      setProductValue(updatedProductValue);
      setFreeProductNew(updatedFreeProductNew);

      setOfferproductQty(isIncrease ? 1 : 0);
    }
  };

  const addFreeProduct = (event) => {
    event.preventDefault();
    const inputCount = productValue.reduce((sum, value) => sum + value.qty, 0);

    if (inputCount > 0) {
      const newItems = productValue
        .filter((item) => item.qty > 0)
        .map((item) => {
          const productData = freeProductData.find(
            (data) => data.productNumber === item.productId
          );

          return {
            qty: item.qty,
            productType: "Promo",
            productCategory:
              productData.categoryId === "1"
                ? "PIZZA"
                : ["10", "13"].includes(productData.categoryId)
                  ? "PASTA, RICE & NOODLE SAUCES"
                  : "BURRITO",
            offerApplicable: "No",
            accountCode: getStorage("accountCode"),
            productImage: productData.productImage,
            packageSize: productData.packageSize,
            productNumber: productData.productNumber,
            productDescription: productData.productDescription,
            productGroup: productData.ProductGroup,
            productUSP: productData.productUSP,
            productDiet: productData.productDiet,
            productSpec: productData.productSpec,
            productIngredient: productData.productIngredient,
            itemPrice: "0",
            itemTotal: "0",
            vat: "0",
          };
        });

      dispatch(addPromoToCart(newItems));
      closefreepro(event);
    } else {
      document.getElementById("alert_message").innerHTML =
        "Please add an item to add to your cart";
    }
  };

  const renderProductRows = () => {
    let isDisabled = false;
    return freeProductNew.map((item, index) => {
      //isDisabled = item.qty === 1;

      return (
        <tr key={item.productNumber}>
          <td data-title={t("product_txt")}>
            <label htmlFor={`freecheckbox_${item.productNumber}`}>
              {item.productDescription} ({item.productNumber})
            </label>

          </td>
          <td data-title={t("unit_of_sale_txt")}>
            <p>
              <strong>{item.packageSize}</strong>
            </p>
          </td>
          <td data-title={t("price_txt")}>
            <h4 className="color-red">
              <strong>
                {priceFormat(0)}
              </strong>
            </h4>
          </td>
          <td
            align="center"
            data-title={t("qty_txt")}
            className={isDisabled ? "disabled" : ""}
          >
            <div className="free-prod-qty-val">
              <span
                title={t("decrease_qty_txt")}
                className="qty-val-action qty-val-minus"
                onClick={(e) => handleFreeProductUpdate(e, false, item, index)}
              >
                -
              </span>
              <input
                type="text"
                id={`input_product_${item.productNumber}`}
                value={item.qty}
                readOnly
              />
              <span
                title={t("increase_qty_txt")}
                className="qty-val-action qty-val-plus"
                onClick={(e) => handleFreeProductUpdate(e, true, item, index)}
              >
                +
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <Popup
        extClass="info-poup free-prod-wrap show"
        id="free-prod-wrap"
        closebtn={<></>}
        headMoreTxt={
          <>
            <span
              title="Close"
              onClick={closefreepro}
              className="close free_product_popup"
            ></span>
            <div className="order-top">
              <h4>
                <span className="fw-block">{t("offer_option_txt")}</span>

              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="checkout-popup checkout-table free-prod-pop">
              <div className="table_overflow">
                <table>
                  <thead>
                    <tr>
                      <td data-title="Product">{t("product_txt")}</td>
                      <td data-title="Unit of Sale">{t("unit_of_sale_txt")}</td>
                      <td data-title="Price">{t("price_txt")}</td>
                      <td align="center" data-title="Quantity">
                        {t("qty_txt")}
                      </td>
                    </tr>
                  </thead>
                  <tbody>{renderProductRows()}</tbody>
                </table>
              </div>
            </div>
            <div className="info-bdy offer_products">
              <div className="alert-message">
                <p id="alert_message"></p>
              </div>
              <div className="btn-item">
                <div className="info-innr">
                  <button
                    type="button"
                    title={t("add_to_cart_txt")}
                    onClick={addFreeProduct}
                  >
                    {t("add_to_cart_txt")}
                  </button>
                </div>
              </div>
              <span className="free-edit-desp">
                {t("change_free_product_msg")}
                <span className="required">*</span>
              </span>
            </div>
          </>
        }
      />
    </div>
  );
};

export default FreeOfferProducts;
