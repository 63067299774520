import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';

export const payConfirm = createAsyncThunk(
  'checkout/payConfirm',
  async (payload) => {
    const response = await http.post('pay', payload);
    return response.data;
  }
);

export const getUnPaidOrders = createAsyncThunk(
  'checkout/getUnPaidOrders',
  async (payload) => {
    const response = await http.post('unpaidorders', payload);
    return response.data;
  }
);

export const getActivePromoOffers = createAsyncThunk(
  'checkout/getActivePromoOffers',
  async () => {
    const response = await http.get('getpromooffers');
    return response.data;
  }
);

const initialState = {
  paymentInfo: [],
  status: 'idle',
  error: null,
  unPaidOrders: [],
  unPaidOrderStatus: 'idle',
  unPaidError: null,
  promoOffers: [],
  promoOffersStatus: 'idle',
  promoOffersError: null
};


const checkoutSlice = createSlice({
  name: 'Checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(payConfirm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(payConfirm.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.paymentInfo = action.payload;
      })
      .addCase(payConfirm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getUnPaidOrders.pending, (state) => {
        state.unPaidOrderStatus = 'loading';
      })
      .addCase(getUnPaidOrders.fulfilled, (state, action) => {
        state.unPaidOrderStatus = 'succeeded';
        state.unPaidOrders = action.payload;
      })
      .addCase(getUnPaidOrders.rejected, (state, action) => {
        state.unPaidOrderStatus = 'failed';
        state.unPaidError = action.error.message;
      })
      .addCase(getActivePromoOffers.pending, (state) => {
        state.promoOffersStatus = 'loading';
      })
      .addCase(getActivePromoOffers.fulfilled, (state, action) => {
        state.promoOffersStatus = 'succeeded';
        state.promoOffers = action.payload;
      })
      .addCase(getActivePromoOffers.rejected, (state, action) => {
        state.promoOffersStatus = 'failed';
        state.promoOffersError = action.error.message;
      });
  },
});

export default checkoutSlice.reducer;